import {useApiConfig} from "~/composables/useApiConfig";
import axios from 'axios';
type User = {
    id: number;
    name: string;
    email: string;
};

type Credentials = {
    email: string;
    password: string;
};

type RegistrationInfo = {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
};

type StripeCustomer = {
    id: string;
};

type StripeSubscription = {
    id: string;
};

type PatreonUserData = {
    accessToken: string;
    user: User;
    tiers: Array<any>;
};

export const useAuthStore = defineStore('auth', () => {
    const user = ref<User | null>(null);
    const isLoggedIn = computed(() => !!user.value);
    const stripeCustomer = ref<StripeCustomer | null>(null);
    const stripeSubscription = ref<StripeSubscription | null>(null);
    const stripeProducts = ref([]);
    const patreonAccessToken = ref<string | null>(null);
    const patreonTiers = ref<Array<any>>([]);
    const { apiBaseURL } = useApiConfig();

    async function logout() {
        sessionStorage.removeItem('patreonTiers');
        sessionStorage.removeItem('patreonUser');
        sessionStorage.removeItem('patreonAccessToken');
        const response = await useApiFetch("/patreon/logout", {
            method: "POST"
        });

        //const { data } = await axios.post(apiBaseURL + '/logout', {withCredentials: true,});

        console.log(response)
        user.value = null;
        patreonAccessToken.value = null;
        patreonTiers.value = [];
        navigateTo("/");
    }

    async function fetchUser() {
        const { data, error } = await useApiFetch("/api/user");
        user.value = data.value as User;
    }
    async function login(credentials: Credentials) {
        await useApiFetch("/sanctum/csrf-cookie");
        const login = await useApiFetch("/login", {
            method: "POST",
            body: credentials,
        });
        await fetchUser();
        return login;
    }

    async function register(info: RegistrationInfo) {
        await useApiFetch("/sanctum/csrf-cookie");
        const login = await useApiFetch("/register", {
            method: "POST",
            body: info,
        });
        await fetchUser();
        return register;
    }

    // async function getStripeCustomer() {
    //     await useApiFetch("/sanctum/csrf-cookie");
    //     const { data } = await useApiFetch("/api/getStripeCustomer", {
    //         method: "POST",
    //         body: user,
    //     });
    //     stripeCustomer.value = data.value;
    //     return stripeCustomer.value;
    // }
    //
    // async function subscription() {
    //     await useApiFetch("/sanctum/csrf-cookie");
    //     const { data } = await useApiFetch("/api/getStripeSubsription", {
    //         method: "POST",
    //         body: user,
    //     });
    //     stripeSubscription.value = data.value;
    //     return stripeSubscription.value;
    // }
    //
    // async function getAllSubscriptionTiers() {
    //     await useApiFetch("/sanctum/csrf-cookie");
    //     const { data } = await useApiFetch("/api/getStripeProducts");
    //     stripeProducts.value = data.value;
    //     return stripeProducts.value;
    // }

    // New methods for handling Patreon user data
    function setPatreonUser(data: PatreonUserData) {
        patreonAccessToken.value = data.accessToken;
        user.value = data.user;
        patreonTiers.value = data.tiers;

        // Optionally save to session storage
        sessionStorage.setItem('patreonAccessToken', data.accessToken);
        sessionStorage.setItem('patreonUser', JSON.stringify(data.user));
        sessionStorage.setItem('patreonTiers', JSON.stringify(data.tiers));
    }

    function loadPatreonUserFromSession() {
        const accessToken = sessionStorage.getItem('patreonAccessToken');
        const patreonUser = sessionStorage.getItem('patreonUser');
        const tiers = sessionStorage.getItem('patreonTiers');

        if (accessToken && patreonUser && tiers) {
            patreonAccessToken.value = accessToken;
            user.value = JSON.parse(patreonUser);
            patreonTiers.value = JSON.parse(tiers);
        }
    }

    async function fetchPatreonUserData(token: string) {
        try {
            console.log("access token :" + token)
            const response = await axios.get(apiBaseURL +'/api/patreon/user', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log("FETCHED : ")
            console.log(response)
            const userData = response.data.user;
            const tiersData = response.data.tiers;

            // Update the store with the fetched user data
            user.value = userData;
            patreonTiers.value = tiersData;
        } catch (error) {
            console.error('Error fetching Patreon user data:', error);
            // Handle error, e.g., clear the access token and redirect to login
            patreonAccessToken.value = null;
            user.value = null;
        }
    }

    return {
        user,
        login,
        isLoggedIn,
        fetchUser,
        logout,
        register,
        // subscription,
        // getAllSubscriptionTiers,
        // getStripeCustomer,
        // stripeCustomer,
        // stripeSubscription,
        // stripeProducts,
        // Patreon-specific methods
        setPatreonUser,
        loadPatreonUserFromSession,
        patreonAccessToken,
        patreonTiers,
        fetchPatreonUserData
    };
});
